import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as ContactDetailsIcon } from "../../../Icons/ContactDetails.svg";
import { ReactComponent as BorderAlertCloseIcon } from "../../../Icons/BorderAlertClose.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { ReactComponent as EditIcon } from "../../../Icons/Edit.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  DatePickerStyle,
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypoGraph,
  TypographyDefault,
} from "../../../Theme";
import {
  Stack,
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { emailRegex, NameRegex, No_special_regex } from "../../../Utils/index";
import AlertModal, { PostLoaderModal } from "../../../CommonComponents/modal";
import axiosInstance from "../../../Redux/AxiosInstance";
import { showToast } from "../../../CommonComponents/Toaster";

let deleteModalDatas = {
  label: "Cancel Onboarding",
  descriptionOne:
    "Please confirm that you wish to cancel the onboarding of Resident. ",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Delete Now",
  },
};

const reviewstyle = {
  minHeight: "61px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "10px 0",
};

const AddResidentSingle = () => {
  const navigate = useNavigate();
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [addblocks, setaddblocks] = useState({
    Personal_Details: true,
    WorkAccess_Details: false,
    Review_Information: false,
  });
  const [PersonalDetailsData, setPersonalDetailsData] = useState({
    firstname: "",
    lastname: "",
    middlename: "",
    dob: "",
    contact: "",
    gender: "",
    nationality: "",
    inorpn: "",
    phone: "",
    countrycode: "",
    email: "",
    address: "",
    country: "",
    city: "",
    postalcode: "",
  });
  const [emergencyContactData, setemergencyContactData] = useState({
    firstname: "",
    lastname: "",
    // gender: '',
    // nationality: '',
    countrycode: "",
    phone: "",
    contact: "",
    email: "",
    relationship: "",
  });
  const [WorkAccessData, setWorkAccessData] = useState({
    residenttype: "",
    building: "",
    level: "",
    unit: "",
    accessstartdate: "",
    accessenddate: "",
  });
  console.log(WorkAccessData);

  const [nationalityData, setnationalityData] = useState("");
  const [genderData, setgenderData] = useState([]);
  const [ResidentTypeData, setResidentTypeData] = useState([]);
  const [buildingData, setBuildingData] = useState([]);
  const [CountryList, setCountryList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [postLoader, SetPostLoader] = useState(false);

  const date = (val) => {
    const formattedDob = moment(val).format("DD MMM YYYY ").toString();
    return formattedDob;
  };

  const [errtext, seterrtext] = useState({
    PersonalDetailsData: {},
    emergencyContactData: {},
    WorkAccessData: {},
  });

  //add api
  const addResident = async () => {
    const AccessStart = WorkAccessData.accessstartdate?.$d;
    const final = moment(AccessStart).hour(0).minute(0).second(0);
    const final1 = moment.utc(final).format("YYYY-MM-DD HH:mm:ss");
    const AccessEnd = WorkAccessData.accessenddate?.$d;
    const finalEnd = moment(AccessEnd).hour(0).minute(0).second(0);
    const final1End = moment.utc(finalEnd).format("YYYY-MM-DD HH:mm:ss");
    const residentData = {
      first_name: PersonalDetailsData.firstname, //required|max:20|min:1
      last_name: PersonalDetailsData.lastname, //required|max:20|min:1
      // middle_name: PersonalDetailsData.middlename, //max:20|min:1
      dob: moment(PersonalDetailsData.dob?.$d).format("YYYY-MM-DD"), //required|date|before_or_equal:today
      gender: PersonalDetailsData.gender, //required|int|in:1,2,3
      nationality: PersonalDetailsData.nationality, //required|max:20|min:1
      identity_number: PersonalDetailsData.inorpn, //required|max:25|min:1
      phone: PersonalDetailsData.phone, //required|min:10|max:20
      country_code: PersonalDetailsData.countrycode, //required
      address: PersonalDetailsData.address, //required|min:3|max:50
      country: PersonalDetailsData.country, //required|min:3|max:20
      city: PersonalDetailsData.city, //required|min:3|max:20
      zipcode: PersonalDetailsData.postalcode, //required|max:7|min:6
      email: PersonalDetailsData.email,
      emergency_details: {
        first_name: emergencyContactData.firstname, //required|max:20|min:1
        last_name: emergencyContactData.lastname, //max:20|min:1
        email: emergencyContactData.email, //email
        phone: emergencyContactData.phone, //required|min:10|max:20
        country_code: emergencyContactData.countrycode, //required
        gender: "1", //required|int|in:1,2,3
        nationality: "kughjv", //required|max:20|min:1
        relationship: emergencyContactData.relationship, //required|max:20|min:1
      },

      access_details: {
        residency_type_id: WorkAccessData.residenttype, //required|exists:residency_type,id
        building_id: WorkAccessData.building,
        level_id: WorkAccessData.level, //exists:building_level,id
        unit_id: WorkAccessData.unit, //exists:building_units,id
        join_date: moment.utc(new Date()).format("YYYY-MM-DD"), //date
        access_start: final1, //required|date
        // "access_end": moment(WorkAccessData.accessenddate?.$d).format('YYYY-MM-DD') //date|after:access_details.access_start
      },
    };

    {
      Boolean(PersonalDetailsData.middlename) &&
        Object.assign(residentData, {
          middle_name: PersonalDetailsData.middlename,
        });
    }
    {
      Boolean(WorkAccessData.accessenddate) &&
        WorkAccessData.residenttype == 5 &&
        (residentData.access_details.access_end = final1End);
    }
    SetPostLoader(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/resident_onboard_single`,
        residentData
      );
      showToast(response.data?.toast);
      navigate("/residents");
      SetPostLoader(false);
    } catch (error) {
      showToast(
        error?.response?.data?.error ||
          "Something went wrong! Please try again.",
        "error"
      );
      SetPostLoader(false);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}getDropDownDatas?type=country`)
      .then((res) => {
        setCountryList(res.data.data?.drop_down);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const getnationality = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=nationality`
        );
        const nationalityData = response.data.data.drop_down;
        var nationalitymenuItems = [];
        for (const key in nationalityData) {
          nationalitymenuItems.push(
            <MenuItem key={key} value={key}>
              {nationalityData[key]}
            </MenuItem>
          );
        }
        setnationalityData(nationalitymenuItems);
      } catch (error) {
        console.log(error.message);
      }
    };
    getnationality();

    const getGenderData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=gender`
        );
        const genderData = response.data.data.drop_down;
        setgenderData(genderData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getGenderData();
    const getResidentTypeData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}getDropDownDatas?type=residency_type_tenant`
        );
        const ResidentTypeData = response.data.data.drop_down;
        setResidentTypeData(ResidentTypeData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getResidentTypeData();

    const getBuildingData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/building`
        );
        const BuildingData = response.data.data.property_buildings;
        setBuildingData(BuildingData);
      } catch (error) {
        console.log(error.message);
      }
    };
    getBuildingData();
  }, []);

  const isValid = (curval) => {
    let isError = false;
    // Personaldetails
    if (curval === "Personal Details") {
      for (const key in PersonalDetailsData) {
        if (key === "email" && PersonalDetailsData[key] !== "") {
          if (!emailRegex.test(PersonalDetailsData[key])) {
            seterrtext((prev) => {
              return {
                ...prev,
                PersonalDetailsData: {
                  ...prev.PersonalDetailsData,
                  [key]: "Error! Invalid Email Format",
                },
              };
            });
            isError = true;
          }
        }
        // else if (key === 'phone' && PersonalDetailsData[key].length < 10) {
        //     seterrtext((prev) => {
        //         return ({
        //             ...prev, PersonalDetailsData: {
        //                 ...prev.PersonalDetailsData,
        //                 [key]: 'Error! Contact number must be above 10 number ',
        //             }
        //         })
        //     })
        //     isError = true
        // }
        else if (key === "phone") {
          if (
            PersonalDetailsData[key] === "" ||
            PersonalDetailsData[key] === null ||
            PersonalDetailsData[key].length < 6
          ) {
            seterrtext((prev) => {
              return {
                ...prev,
                PersonalDetailsData: {
                  ...prev.PersonalDetailsData,
                  [key]:
                    PersonalDetailsData[key] === "" ||
                    PersonalDetailsData[key] === null
                      ? "Error! No Inputs detected"
                      : "Error! Phone should not below 6 numbers ",
                },
              };
            });
            isError = true;
          }
        } else if (key === "postalcode") {
          if (
            PersonalDetailsData[key] === "" ||
            PersonalDetailsData[key] === null
          ) {
            seterrtext((prev) => {
              return {
                ...prev,
                PersonalDetailsData: {
                  ...prev.PersonalDetailsData,
                  [key]: "Error! No Inputs detected",
                },
              };
            });
            isError = true;
          } else if (PersonalDetailsData[key].length < 5) {
            seterrtext((prev) => {
              return {
                ...prev,
                PersonalDetailsData: {
                  ...prev.PersonalDetailsData,
                  [key]: "Error! Postal Code must be above 5 numbers ",
                },
              };
            });
            isError = true;
          }
        } else if (
          (PersonalDetailsData[key] === "" ||
            PersonalDetailsData[key] === null) &&
          key !== "middlename"
        ) {
          seterrtext((prev) => {
            return {
              ...prev,
              PersonalDetailsData: {
                ...prev.PersonalDetailsData,
                [key]: "Error! No Inputs detected",
              },
            };
          });
          isError = true;
        }
      }
      //emergencycontact
      for (const key in emergencyContactData) {
        if (key === "email" && emergencyContactData[key] !== "") {
          if (!emailRegex.test(emergencyContactData[key])) {
            seterrtext((prev) => {
              return {
                ...prev,
                emergencyContactData: {
                  ...prev.emergencyContactData,
                  [key]: "Error! Invalid Email Format",
                },
              };
            });
            isError = true;
          }
        }
        // else if (key === 'phone' && emergencyContactData[key].length < 10) {
        //     seterrtext((prev) => {
        //         return ({
        //             ...prev, emergencyContactData: {
        //                 ...prev.emergencyContactData,
        //                 [key]: 'Error! Contact number must be above 10 number ',
        //             }
        //         })
        //     })
        //     isError = true
        // }
        else if (key === "phone") {
          if (
            emergencyContactData[key] === "" ||
            emergencyContactData[key] === null ||
            emergencyContactData[key].length < 6
          ) {
            seterrtext((prev) => {
              return {
                ...prev,
                emergencyContactData: {
                  ...prev.emergencyContactData,
                  [key]:
                    emergencyContactData[key] === "" ||
                    emergencyContactData[key] === null
                      ? "Error! No Inputs detected"
                      : "Error! Phone should not below 6 numbers ",
                },
              };
            });
            isError = true;
          }
        } else if (emergencyContactData[key] === "" && key !== "lastname") {
          seterrtext((prev) => {
            return {
              ...prev,
              emergencyContactData: {
                ...prev.emergencyContactData,
                [key]: "Error! No Inputs detected",
              },
            };
          });
          isError = true;
        }
      }
    } else if (curval === "Access Details") {
      for (const key in WorkAccessData) {
        if (
          key === "accessenddate" &&
          WorkAccessData.residenttype != "3" &&
          WorkAccessData.residenttype != "4"
        ) {
          if (WorkAccessData[key] === "" || WorkAccessData[key] === null) {
            seterrtext((prev) => {
              return {
                ...prev,
                WorkAccessData: {
                  ...prev.WorkAccessData,
                  [key]: "Error! There is no input filled.",
                },
              };
            });
            isError = true;
          } else if (
            !(
              WorkAccessData.accessstartdate.$d <=
              WorkAccessData.accessenddate.$d
            )
          ) {
            seterrtext((prev) => {
              return {
                ...prev,
                WorkAccessData: {
                  ...prev.WorkAccessData,
                  [key]: "Error! End date should be greater than start date",
                },
              };
            });
            isError = true;
          }
        } else if (
          (WorkAccessData[key] === "" || WorkAccessData[key] === null) &&
          key !== "accessenddate"
        ) {
          seterrtext((prev) => {
            return {
              ...prev,
              WorkAccessData: {
                ...prev.WorkAccessData,
                [key]: "Error! There is no input filled.",
              },
            };
          });
          isError = true;
        }
      }
    }
    return isError;
  };
  //personalDetails
  const Continue = (nxtval, curval) => {
    if (nxtval === "Access Details") {
      curval = "Personal Details";
    } else if (nxtval === "Review Information") {
      curval = "Access Details";
    }
    const Valid = isValid(curval);

    if (!Valid) {
      if (nxtval === "Personal Details") {
        setaddblocks({
          Personal_Details: true,
          WorkAccess_Details: false,
          Review_Information: false,
        });
      } else if (nxtval === "Access Details") {
        setaddblocks({
          Personal_Details: false,
          WorkAccess_Details: true,
          Review_Information: false,
        });
      } else if (nxtval === "Review Information") {
        setaddblocks({
          Personal_Details: false,
          WorkAccess_Details: false,
          Review_Information: true,
        });
      }
    }
  };

  const handlePersonalDetails = (e, name) => {
    if (name) {
      setPersonalDetailsData((prev) => {
        return {
          ...prev,
          [name]: e,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          PersonalDetailsData: {
            ...prev.PersonalDetailsData,
            [name]: "",
          },
        };
      });
    } else {
      const { name, value } = e.target;
      if (name === "country" && value) {
        setCityList([]);
        const selectedCountry = value;
        const selectedCountryObject = CountryList.find(
          (city) => city.country === selectedCountry
        );
        setCityList(selectedCountryObject?.city);
        setPersonalDetailsData((prevData) => ({
          ...prevData,
          city: "",
        }));
      }
      setPersonalDetailsData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          PersonalDetailsData: {
            ...prev.PersonalDetailsData,
            [name]: "",
          },
        };
      });
    }
  };
  const handleMobileInput = (
    value,
    country,
    functionName,
    state,
    statename
  ) => {
    functionName({
      ...state,
      contact: value,
      countrycode: `+${country.dialCode}`,
      phone: value.slice(country.dialCode.length),
    });
    seterrtext((prev) => {
      return {
        ...prev,
        [statename]: {
          ...prev[statename],
          countrycode: "",
          phone: "",
        },
      };
    });
  };

  const handleEmergencyContact = (e) => {
    const { name, value } = e.target;
    setemergencyContactData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    seterrtext((prev) => {
      return {
        ...prev,
        emergencyContactData: {
          ...prev.emergencyContactData,
          [name]: "",
        },
      };
    });
  };

  const handleWorkAccess = (e, name) => {
    if (name) {
      if (name == "accessstartdate") {
        setWorkAccessData((prev) => {
          return {
            ...prev,
            ["accessenddate"]: "",
          };
        });
      }
      setWorkAccessData((prev) => {
        return {
          ...prev,
          [name]: e,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          WorkAccessData: {
            ...prev.WorkAccessData,
            [name]: "",
          },
        };
      });
    } else {
      const { name, value } = e.target;
      setWorkAccessData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      seterrtext((prev) => {
        return {
          ...prev,
          WorkAccessData: {
            ...prev.WorkAccessData,
            [name]: "",
          },
        };
      });
      if (name == "residenttype" && (value == "3" || value == "4")) {
        seterrtext((prev) => {
          return {
            ...prev,
            WorkAccessData: {
              ...prev.WorkAccessData,
              accessenddate: "",
            },
          };
        });
      }
    }
  };
  const DropdownEmpty = (val) => {
    if (val === "building") {
      setWorkAccessData((prev) => {
        return {
          ...prev,
          level: "",
          unit: "",
        };
      });
    } else if (val === "level") {
      setWorkAccessData((prev) => {
        return {
          ...prev,
          unit: "",
        };
      });
    }
  };

  const blocks = [
    { status: addblocks.Personal_Details, tle: "Personal Details" },
    { status: addblocks.WorkAccess_Details, tle: "Access Details" },
    { status: addblocks.Review_Information, tle: "Review Information" },
  ];

  const PersonalDetails = () => {
    return (
      <>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>Personal Details</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8}>
              <StyledTypography>First Name*</StyledTypography>
              <TextField
                name="firstname"
                value={PersonalDetailsData.firstname}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.firstname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.firstname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.firstname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8}>
              <StyledTypography>Last Name*</StyledTypography>
              <TextField
                name="lastname"
                value={PersonalDetailsData.lastname}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                onChange={handlePersonalDetails}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.lastname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.lastname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.lastname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Middle Name</StyledTypography>
              <TextField
                name="middlename"
                value={PersonalDetailsData.middlename}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.middlename
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.middlename
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.middlename}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Date of Birth*</StyledTypography>
              <DatePicker
                disableFuture
                format="DD/MM/YYYY"
                slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                name="dob"
                value={PersonalDetailsData.dob}
                onChange={(value) => handlePersonalDetails(value, "dob")}
                sx={{
                  ...DatePickerStyle,
                  backgroundColor: Boolean(errtext.PersonalDetailsData.dob)
                    ? "#FEE4E2"
                    : "",

                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.dob
                      ? "#912018"
                      : "#111927",
                  },
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.dob}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Gender*</StyledTypography>
              <Select
                name="gender"
                IconComponent={KeyboardArrowDownIcon}
                value={PersonalDetailsData.gender}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.gender
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: errtext.PersonalDetailsData.gender
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {genderData.map((itm) => {
                  return <MenuItem value={itm.id}>{itm.name}</MenuItem>;
                })}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.gender}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Nationality*</StyledTypography>
              <Select
                name="nationality"
                IconComponent={KeyboardArrowDownIcon}
                value={PersonalDetailsData.nationality}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.nationality
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: errtext.PersonalDetailsData.nationality
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {Boolean(nationalityData) && nationalityData}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.nationality}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>
                Identity Number / Passport Number*
              </StyledTypography>
              <TextField
                name="inorpn"
                value={PersonalDetailsData.inorpn}
                onChange={handlePersonalDetails}
                onKeyDown={(evt) => {
                  !No_special_regex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 25 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.inorpn
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.inorpn
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.inorpn}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography sx={{ marginBottom: "10px" }}>
                Contact Number*
              </StyledTypography>
              <PhoneInput
                value={PersonalDetailsData.contact}
                onChange={(value, country, e) =>
                  handleMobileInput(
                    value,
                    country,
                    setPersonalDetailsData,
                    PersonalDetailsData,
                    "PersonalDetailsData"
                  )
                }
                inputProps={{ name: "phone", maxLength: 20 }}
                country={"th"}
                buttonStyle={{ border: "none" }}
                inputStyle={{
                  backgroundColor: errtext.PersonalDetailsData.phone
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  border: "none",
                  height: "46px",
                  width: "100%",
                  borderRadius: "8px",
                  color: errtext.PersonalDetailsData.phone
                    ? "#912018"
                    : "#111927",
                }}
                countryCodeEditable={false}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.phone}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={12} mt={2}>
              <StyledTypography>Email Address*</StyledTypography>
              <TextField
                name="email"
                value={PersonalDetailsData.email}
                onChange={handlePersonalDetails}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.email
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.email
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.email}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={12} mt={2}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <StyledTypography>Address*</StyledTypography>
                <TypoGraph>{PersonalDetailsData?.address?.length}/50</TypoGraph>
              </Box>
              <TextField
                name="address"
                placeholder="Enter the full address here"
                value={PersonalDetailsData.address}
                onChange={handlePersonalDetails}
                inputProps={{ maxLength: 50 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.address
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.address
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  height: "88px",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.address}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={3.77} mt={2}>
              <StyledTypography>Country*</StyledTypography>
              <Select
                name="country"
                IconComponent={KeyboardArrowDownIcon}
                value={PersonalDetailsData.country}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.country
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: errtext.PersonalDetailsData.country
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {CountryList.length > 0 &&
                  CountryList.map((value) => (
                    <MenuItem key={value?.key} value={value?.country}>
                      {value?.country}
                    </MenuItem>
                  ))}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.country}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={3.77} mt={2}>
              <StyledTypography>City*</StyledTypography>
              <Select
                name="city"
                IconComponent={KeyboardArrowDownIcon}
                value={PersonalDetailsData.city}
                onChange={(e) => handlePersonalDetails(e)}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.city
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: errtext.PersonalDetailsData.city
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              >
                {CityList.length > 0 &&
                  CityList.map((city) => (
                    <MenuItem value={city}>{city}</MenuItem>
                  ))}
              </Select>
              <ErrorTypography>
                {errtext.PersonalDetailsData.city}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={3.77} mt={2}>
              <StyledTypography>Postal Code*</StyledTypography>
              <TextField
                type="number"
                name="postalcode"
                value={PersonalDetailsData.postalcode}
                onChange={(e) => {
                  e.target.value.length <= 6 && handlePersonalDetails(e);
                }}
                // inputProps={{ maxLength: 6 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.PersonalDetailsData.postalcode
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.PersonalDetailsData.postalcode
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.PersonalDetailsData.postalcode}
              </ErrorTypography>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>Emergency Contact</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px",
            }}
          >
            <Grid xs={12} sm={5.8}>
              <StyledTypography>First Name*</StyledTypography>
              <TextField
                name="firstname"
                value={emergencyContactData.firstname}
                onChange={handleEmergencyContact}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.firstname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.firstname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.firstname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8}>
              <StyledTypography>Last Name</StyledTypography>
              <TextField
                name="lastname"
                value={emergencyContactData.lastname}
                onChange={handleEmergencyContact}
                onKeyDown={(evt) => {
                  !NameRegex.test(evt.key) && evt.preventDefault();
                }}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.lastname
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.lastname
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.lastname}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Email Address*</StyledTypography>
              <TextField
                name="email"
                value={emergencyContactData.email}
                onChange={handleEmergencyContact}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 125 }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.email
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.email
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.email}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Contact Number*</StyledTypography>
              <PhoneInput
                value={emergencyContactData.contact}
                onChange={(value, country, e) =>
                  handleMobileInput(
                    value,
                    country,
                    setemergencyContactData,
                    emergencyContactData,
                    "emergencyContactData"
                  )
                }
                inputProps={{ name: "phone", maxLength: 20 }}
                country={"th"}
                buttonStyle={{ border: "none" }}
                inputStyle={{
                  backgroundColor: errtext.emergencyContactData.phone
                    ? "#FEE4E2"
                    : " #F9FAFB",
                  border: "none",
                  height: "46px",
                  width: "100%",
                  borderRadius: "8px",
                  color: errtext.emergencyContactData.phone
                    ? "#912018"
                    : "#111927",
                }}
                countryCodeEditable={false}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.phone}
              </ErrorTypography>
            </Grid>
            {/* <Grid xs={12} sm={3.77} mt={2}>
                            <StyledTypography>Nationality*</StyledTypography>
                            <Select name="nationality" value={emergencyContactData.nationality} onChange={(e) => handleEmergencyContact(e)}>
                                {Boolean(nationalityData) && nationalityData}
                            </Select>
                            <ErrorTypography>{errtext.emergencyContactData.nationality}</ErrorTypography>
                        </Grid>
                        <Grid xs={12} sm={3.77} mt={2}>
                            <StyledTypography>Gender*</StyledTypography>
                            <Select name="gender" value={emergencyContactData.gender} onChange={(e) => handleEmergencyContact(e)}>
                                {genderData.map((itm) => {
                                    return <MenuItem value={itm.id}>{itm.name}</MenuItem>
                                })}
                            </Select>
                            <ErrorTypography>{errtext.emergencyContactData.gender}</ErrorTypography>
                        </Grid> */}
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Relationship*</StyledTypography>
              <TextField
                name="relationship"
                value={emergencyContactData.relationship}
                onChange={handleEmergencyContact}
                inputProps={{ maxLength: 20 }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.emergencyContactData.relationship
                      ? "#912018"
                      : "#111927",
                  },
                  backgroundColor: errtext.emergencyContactData.relationship
                    ? "#FEE4E2"
                    : " #F9FAFB",
                }}
              />
              <ErrorTypography>
                {errtext.emergencyContactData.relationship}
              </ErrorTypography>
            </Grid>
          </Grid>
        </Box>

        <Box
          mt={3}
          bgcolor="#FFFFFF"
          sx={{
            border: "1px solid  #E5E7EB",
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "8px 8px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="end"
            alignItems="center"
            height="60px"
          >
            {/* <Stack direction='row'>
                            <RefreshIcon style={{ marginRight: '5px' }} />
                            <StyledSecondaryTypography>Last Updated at  {moment(new Date()).format('HH:mm A')}</StyledSecondaryTypography>
                        </Stack> */}
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                type="button"
                onClick={() => Continue("Access Details", "Personal Details")}
                variant="Standard"
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };
  const WorkAccessDetails = () => {
    return (
      <>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <ContactDetailsIcon />
            <SecondaryTypography>Access Details</SecondaryTypography>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Resident Type*</StyledTypography>
              <Select
                name="residenttype"
                IconComponent={KeyboardArrowDownIcon}
                value={WorkAccessData.residenttype}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.residenttype
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: Boolean(errtext.WorkAccessData.residenttype)
                    ? "#FEE4E2"
                    : "",
                }}
                onChange={(e) => handleWorkAccess(e)}
              >
                {ResidentTypeData.map((itm) => {
                  return (
                    <MenuItem key={itm.key} value={itm.id}>
                      {itm.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <ErrorTypography>
                {errtext.WorkAccessData.residenttype}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Building</StyledTypography>
              <Select
                name="building"
                IconComponent={KeyboardArrowDownIcon}
                value={WorkAccessData.building}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.building
                      ? "#912018"
                      : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: Boolean(errtext.WorkAccessData.building)
                    ? "#FEE4E2"
                    : "",
                }}
                onChange={(e) => handleWorkAccess(e)}
              >
                {buildingData.map((itm, index) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        DropdownEmpty("building");
                      }}
                      key={index}
                      value={itm.id}
                    >
                      {itm.building_name}
                    </MenuItem>
                  );
                })}
              </Select>
              <ErrorTypography>
                {errtext.WorkAccessData.building}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>level*</StyledTypography>
              <Select
                name="level"
                IconComponent={KeyboardArrowDownIcon}
                value={WorkAccessData.level}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.level ? "#912018" : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: Boolean(errtext.WorkAccessData.level)
                    ? "#FEE4E2"
                    : "",
                }}
                onChange={(e) => handleWorkAccess(e)}
              >
                {buildingData.map((itm, index) => {
                  if (WorkAccessData.building == itm.id) {
                    return itm.level.map((sitm) => {
                      return (
                        <MenuItem
                          onClick={() => {
                            DropdownEmpty("level");
                          }}
                          value={sitm.id}
                        >
                          {sitm.level}
                        </MenuItem>
                      );
                    });
                  }
                })}
              </Select>
              <ErrorTypography>{errtext.WorkAccessData.level}</ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Unit*</StyledTypography>
              <Select
                name="unit"
                IconComponent={KeyboardArrowDownIcon}
                value={WorkAccessData.unit}
                sx={{
                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.unit ? "#912018" : "#111927",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:"none !important"
                  },
                  backgroundColor: Boolean(errtext.WorkAccessData.unit)
                    ? "#FEE4E2"
                    : "",
                }}
                onChange={(e) => handleWorkAccess(e)}
              >
                {buildingData.map((itm, index) => {
                  if (WorkAccessData.building == itm.id) {
                    return itm.level.map((sitm) => {
                      if (WorkAccessData.level == sitm.id) {
                        return sitm.building_unit.map((ssitm) => {
                          return (
                            <MenuItem value={ssitm.id}>
                              {ssitm.unit_no}
                            </MenuItem>
                          );
                        });
                      }
                    });
                  }
                })}
              </Select>
              <ErrorTypography>{errtext.WorkAccessData.unit}</ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Access Start Date*</StyledTypography>
              <DatePicker
                format="DD/MM/YYYY"
                slots={{
                  openPickerIcon: () => <DatePickerIcon />,
                }}
                name="accessstartdate"
                value={WorkAccessData.accessstartdate}
                onChange={(value) => handleWorkAccess(value, "accessstartdate")}
                sx={{
                  ...DatePickerStyle,
                  backgroundColor: Boolean(
                    errtext.WorkAccessData.accessstartdate
                  )
                    ? "#FEE4E2"
                    : "",

                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.accessstartdate
                      ? "#912018"
                      : "#111927",
                  },
                }}
              />
              <ErrorTypography>
                {errtext.WorkAccessData.accessstartdate}
              </ErrorTypography>
            </Grid>
            <Grid xs={12} sm={5.8} mt={2}>
              <StyledTypography>Access End Date*</StyledTypography>
              <DatePicker
                minDate={WorkAccessData.accessstartdate}
                format="DD/MM/YYYY"
                slots={{
                  openPickerIcon: () => <DatePickerIcon />,
                }}
                name="accessenddate"
                value={WorkAccessData.accessenddate}
                onChange={(value) => handleWorkAccess(value, "accessenddate")}
                sx={{
                  ...DatePickerStyle,
                  backgroundColor: Boolean(errtext.WorkAccessData.accessenddate)
                    ? "#FEE4E2"
                    : "",

                  "& .MuiInputBase-root": {
                    color: errtext.WorkAccessData.accessenddate
                      ? "#912018"
                      : "#111927",
                  },
                }}
              />
              <ErrorTypography>
                {errtext.WorkAccessData.accessenddate}
              </ErrorTypography>
            </Grid>
          </Grid>
        </Box>

        <Box
          mt={3}
          bgcolor="#FFFFFF"
          sx={{
            border: "1px solid  #E5E7EB",
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "8px 8px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="end"
            alignItems="center"
            height="60px"
          >
            {/* <Stack direction='row'>
                            <RefreshIcon style={{ marginRight: '5px' }} />
                            <StyledSecondaryTypography>Last Updated at  {moment(new Date()).format('HH:mm A')}</StyledSecondaryTypography>
                        </Stack> */}
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button
                type="button"
                onClick={() => Continue("Review Information", "Access Details")}
                variant="Standard"
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };
  const ReviewInformation = () => {
    return (
      <>
        {/* Personal Details */}
        <Box
          my={5}
          minHeight="100px"
          padding="20px 16px"
          backgroundColor="#FFFAEB"
          border="1px solid #FEE6A1"
        >
          <StyledTypography mb="10px" sx={{ color: "#B54708 !important" }}>
            Onboarding Alert
          </StyledTypography>
          <TypographyDefault sx={{ color: "#B54708 !important" }}>
            Please ensure that all data provided are accurate to prevent any
            delays in user access. Once tenant is onboarded, they will receive
            an onboarding email to download and install the LOBY App. Tenants
            will be able to use the app once the selected access start date has
            been reached.
          </TypographyDefault>
        </Box>
        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <Stack direction="row" alignItems="center" gap="10px">
              <ContactDetailsIcon />
              <SecondaryTypography>Personal Details</SecondaryTypography>
            </Stack>
            <Button
              onClick={() => Continue("Personal Details", "")}
              sx={{
                width: "83px",
                height: "36px",
                padding: "8px 6px 8px 14px",
              }}
              variant="outlined"
            >
              {" "}
              Edit{" "}
              <IconButton sx={{ paddingLeft: "10px" }}>
                {" "}
                <EditIcon width="14px" />
              </IconButton>
            </Button>
          </Stack>

          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>First Name</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.firstname}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Last Name</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.lastname}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Middle Name</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.middlename}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Date of Birth
              </StyledSecondaryTypography>
              <TypographyDefault>
                {date(PersonalDetailsData.dob.$d)}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
              {genderData.map((itm) => {
                if (PersonalDetailsData.gender === itm.id) {
                  return <TypographyDefault>{itm.name}</TypographyDefault>;
                }
              })}
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Nationality</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.nationality}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Identity Number / Passport Number
              </StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.inorpn}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography sx={{ marginBottom: "10px" }}>
                Contact Number
              </StyledSecondaryTypography>
              <TypographyDefault>{PersonalDetailsData.phone}</TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Email Address
              </StyledSecondaryTypography>
              <TypographyDefault>{PersonalDetailsData.email}</TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Address</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.address}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Country</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.country}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>City</StyledSecondaryTypography>
              <TypographyDefault>{PersonalDetailsData.city}</TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Postal Code</StyledSecondaryTypography>
              <TypographyDefault>
                {PersonalDetailsData.postalcode}
              </TypographyDefault>
            </Grid>
          </Grid>
        </Box>

        {/* Emergency Contact */}

        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <Stack direction="row" alignItems="center" gap="10px">
              <ContactDetailsIcon />
              <SecondaryTypography>Emergency Contact</SecondaryTypography>
            </Stack>
            <Button
              onClick={() => Continue("Personal Details", "")}
              sx={{
                width: "83px",
                height: "36px",
                padding: "8px 6px 8px 14px",
              }}
              variant="outlined"
            >
              {" "}
              Edit{" "}
              <IconButton sx={{ paddingLeft: "10px" }}>
                {" "}
                <EditIcon width="14px" />
              </IconButton>
            </Button>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>First Name</StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.firstname}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Last Name</StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.lastname}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Email Address
              </StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.email}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Contact Number
              </StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.phone}
              </TypographyDefault>
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            {/* <Grid xs={12} sm={5.8} sx={reviewstyle}>
                            <StyledSecondaryTypography>Nationality</StyledSecondaryTypography>
                            <TypographyDefault>{emergencyContactData.nationality}</TypographyDefault>
                        </Grid>
                        <Grid xs={12} sm={5.8} sx={reviewstyle}>
                            <StyledSecondaryTypography>Gender</StyledSecondaryTypography>
                            {genderData.map((itm) => {
                                if (emergencyContactData.gender === itm.id) {
                                    return <TypographyDefault>{itm.name}</TypographyDefault>
                                }
                            })}
                        </Grid>
                        <Divider sx={{ borderTop: '1px solid #F3F4F6', width: '100%', marginTop: '10px' }} /> */}
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Relationship
              </StyledSecondaryTypography>
              <TypographyDefault>
                {emergencyContactData.relationship}
              </TypographyDefault>
            </Grid>
          </Grid>
        </Box>

        {/* Access Details */}

        <Box
          mt={3}
          component="div"
          boxShadow="0 0 3px 0 rgba(0 ,0 ,0 ,0.1)"
          borderRadius="8px"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px="16px"
            sx={{
              height: "66px",
              backgroundColor: "#FCFCFD",
              border: "1px solid #E5E7EB",
              borderRadius: "8px 8px 0 0 ",
            }}
          >
            <Stack direction="row" alignItems="center" gap="10px">
              <ContactDetailsIcon />
              <SecondaryTypography>Access Details</SecondaryTypography>
            </Stack>
            <Button
              onClick={() => Continue("Access Details", "")}
              sx={{
                width: "83px",
                height: "36px",
                padding: "8px 6px 8px 14px",
              }}
              variant="outlined"
            >
              {" "}
              Edit{" "}
              <IconButton sx={{ paddingLeft: "10px" }}>
                {" "}
                <EditIcon width="14px" />
              </IconButton>
            </Button>
          </Stack>
          <Grid
            container
            xs={12}
            gap="2.8%"
            sx={{
              backgroundColor: "white",
              padding: "30px 16px 30px 16px",
              border: "1px solid #E5E7EB",
              borderRadius: "0 0 8px 8px  ",
            }}
          >
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Resident Type
              </StyledSecondaryTypography>
              <TypographyDefault>
                {WorkAccessData.residenttype == 3 && "Owner"}
                {WorkAccessData.residenttype == 4 && "Sub Owner"}
                {WorkAccessData.residenttype == 5 && "Tenant"}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Building</StyledSecondaryTypography>
              {buildingData.map((itm, index) => {
                if (WorkAccessData.building == itm.id) {
                  return (
                    <TypographyDefault>{itm.building_name}</TypographyDefault>
                  );
                }
              })}
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>level</StyledSecondaryTypography>
              {buildingData.map((itm, index) => {
                if (WorkAccessData.building == itm.id) {
                  return itm.level.map((sitm) => {
                    if (WorkAccessData.level == sitm.id) {
                      return (
                        <TypographyDefault>{sitm.level}</TypographyDefault>
                      );
                    }
                  });
                }
              })}
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>Unit</StyledSecondaryTypography>
              {buildingData.map((itm, index) => {
                if (WorkAccessData.building == itm.id) {
                  return itm.level.map((sitm) => {
                    if (WorkAccessData.level == sitm.id) {
                      return sitm.building_unit.map((ssitm) => {
                        if (WorkAccessData.unit == ssitm.id) {
                          return (
                            <TypographyDefault>
                              {ssitm.unit_no}
                            </TypographyDefault>
                          );
                        }
                      });
                    }
                  });
                }
              })}
            </Grid>
            <Divider
              sx={{
                borderTop: "1px solid #F3F4F6",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Access Start Date
              </StyledSecondaryTypography>
              <TypographyDefault>
                {date(WorkAccessData.accessstartdate.$d)}
              </TypographyDefault>
            </Grid>
            <Grid xs={12} sm={5.8} sx={reviewstyle}>
              <StyledSecondaryTypography>
                Access End Date
              </StyledSecondaryTypography>
              <TypographyDefault>
                {" "}
                {WorkAccessData.accessenddate
                  ? date(WorkAccessData.accessenddate.$d)
                  : "-"}
              </TypographyDefault>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={3}
          bgcolor="#FFFFFF"
          sx={{
            border: "1px solid  #E5E7EB",
            borderTop: " 1px solid #F3F4F6",
            boxShadow: "0 0 3px 0 rgba(0 ,0 ,0 ,0.1)",
            borderRadius: "8px 8px 8px 8px",
          }}
        >
          <Stack
            direction="row"
            py={2}
            px={2}
            justifyContent="end"
            alignItems="center"
            height="60px"
          >
            {/* <Stack direction='row'>
                            <RefreshIcon style={{ marginRight: '5px' }} />
                            <StyledSecondaryTypography>Last Updated at  {moment(new Date()).format('HH:mm A')}</StyledSecondaryTypography>
                        </Stack> */}
            <Stack direction="column" justifyContent="end" alignItems="end">
              <Button type="button" onClick={addResident} variant="Standard">
                Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  };

  const deleteContactDetail = () => {
    navigate("/residents");
  };
  const handleShowDeleteModal = () => {
    setshowDeleteModal(!showDeleteModal);
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {showDeleteModal && (
        <AlertModal
          modalDatas={deleteModalDatas}
          confirmFunction={deleteContactDetail}
          closeFunction={handleShowDeleteModal}
        />
      )}
      <Box p={5} sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack mb={3} direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => navigate("/residents")}
            style={{ marginBottom: "16px", cursor: "pointer" }}
          />
          <BorderAlertCloseIcon
            width="40"
            height="40"
            cursor="pointer"
            onClick={() => setshowDeleteModal(true)}
          />
        </Stack>
        <Box mb={5}>
          <PrimaryTypography mb={1}>Resident Onboarding</PrimaryTypography>
          <StyledSecondaryTypography>
            Fill in details below to onboard new resident. For onboarding of
            multiple residents, select batch onboarding instead.
          </StyledSecondaryTypography>
        </Box>
        <Grid container gap="1.5%">
          {blocks.map((itm) => {
            return (
              <Grid
                xs={3.87}
                onClick={() => Continue(itm.tle, "")}
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                <Box
                  component="div"
                  sx={{
                    borderTop: itm.status
                      ? "3px solid #0E4965"
                      : "3px solid #D2D6DB",
                    width: "100%",
                    color: itm.status ? "#0E4965" : "#9DA4AE",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "10px 0 0 0",
                    }}
                  >
                    {itm.tle}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {addblocks.Personal_Details && PersonalDetails()}
        {addblocks.WorkAccess_Details && WorkAccessDetails()}
        {addblocks.Review_Information && ReviewInformation()}
      </Box>
    </>
  );
};

export default AddResidentSingle;
