import { Button, TextField, Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as PasswordVisible } from "../Icons/PasswordVisible.svg";
import { ReactComponent as PasswordInvisible } from "../Icons/PasswordInvisible.svg";
import { ReactComponent as BackArrow } from "../Icons/BackArrow.svg";
import { emailRegex } from "../Utils";
import {
  ErrorTypography,
  StyledPrimaryTypography,
  StyledTypography,
} from "../Theme";
import {
  getMenu,
  login,
  setLoginSliceToNull,
  setUserDetails,
} from "../Redux/Feature/Login/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setActiveStep,
  setPropertyId,
  setUserRole,
} from "../Redux/Feature/PropertyDetails/propertyDetailsSlice";

const Login = ({ setDisplayScreen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginStatus } = useSelector((state) => state.Login);
  console.log("login", loginStatus);
  const [data, setData] = useState({ email: "", password: "" });
  const [errorText, setErrorText] = useState({ email: "", password: "" });
  const [responseError, setResponseError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    if (loginStatus != null) {
      setLoadingBtn(false);
      if (loginStatus.success) {
        if (loginStatus.data.user_details.user_role == 2) {
          if (loginStatus.data.property_status.status === "draft") {
            localStorage.clear();
            localStorage.setItem(
              "accessToken",
              loginStatus.data.token.access_token
            );
            localStorage.setItem(
                "refreshToken",
                loginStatus.data.token.refresh_token
              );
              localStorage.setItem(
                "expireTime",
                loginStatus.data.token.expires_in
              );

            localStorage.setItem(
              "propertyStatus",
              JSON.stringify(loginStatus.data.property_status)
            );
            localStorage.setItem(
              "userDetails",
              JSON.stringify(loginStatus.data.user_details)
            );
            // dispatch(setActiveStep(loginStatus.data.property_status.completed_step))
            // dispatch(setPropertyId(loginStatus.data.property_status.id))
            navigate("/propertyDetailsSetup");
            dispatch(setLoginSliceToNull());
            dispatch(setPropertyId(loginStatus.data.property_status.id));
            dispatch(setUserRole(loginStatus.data.user_details.user_role));
            dispatch(setUserDetails(loginStatus.data.user_details));
          } else if (loginStatus.data.property_status.status !== "draft") {
            localStorage.clear();
            localStorage.setItem(
              "propertyStatus",
              JSON.stringify(loginStatus.data.property_status)
            );
            localStorage.setItem(
              "accessToken",
              loginStatus.data.token.access_token
            );
            localStorage.setItem(
                "refreshToken",
                loginStatus.data.token.refresh_token
              );
              localStorage.setItem(
                "expireTime",
                loginStatus.data.token.expires_in
              );

            localStorage.setItem(
              "userDetails",
              JSON.stringify(loginStatus.data.user_details)
            );
            navigate("overview");
            dispatch(setLoginSliceToNull());
            dispatch(setPropertyId(loginStatus.data.property_status.id));
            dispatch(setUserRole(loginStatus.data.user_details.user_role));
            dispatch(setUserDetails(loginStatus.data.user_details));
          }
        } else if (loginStatus.data.user_details.user_role == 3) {
          if (loginStatus.data.user_property[0].status === "published") {
            localStorage.clear();
            localStorage.setItem(
              "propertyStatus",
              JSON.stringify(loginStatus.data.user_property[0])
            );
            localStorage.setItem(
              "accessToken",
              loginStatus.data.token.access_token
            );
            localStorage.setItem(
                "refreshToken",
                loginStatus.data.token.refresh_token
              );
              localStorage.setItem(
                "expireTime",
                loginStatus.data.token.expires_in
              );

            localStorage.setItem(
              "userDetails",
              JSON.stringify(loginStatus.data.user_details)
            );
            navigate("overview");
            dispatch(setLoginSliceToNull());
            dispatch(setPropertyId(loginStatus.data.user_property[0].id));
            dispatch(setUserRole(loginStatus.data.user_details.user_role));
            dispatch(setUserDetails(loginStatus.data.user_details));
          }
          // setResponseError('No Access on Web')
        }
      } else {
        setResponseError(loginStatus.error);
      }
    }
  }, [loginStatus]);

  const handleData = (e) => {
    let { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    setErrorText({
      ...errorText,
      [name]: "",
    });
    setResponseError("");
  };

  const validate = () => {
    let isError = [];
    for (let key in data) {
      if (key === "email" && data[key] != "") {
        if (!emailRegex.test(data[key])) {
          setErrorText((prev) => {
            return {
              ...prev,
              [key]: "Enter valid email",
            };
          });
          isError.push(true);
        }
      }
      if (data[key] === "") {
        setErrorText((prev) => {
          return {
            ...prev,
            [key]: "Error! No Inputs detected",
          };
        });
        isError.push(true);
      }
    }
    return isError.includes(true);
  };

  const handleLogin = () => {
    let isError = validate();
    if (!isError) {
      dispatch(setLoginSliceToNull());
      setLoadingBtn(true);
      dispatch(login({ email: data.email, password: data.password }));
    }
  };
  return (
    <>
      <BackArrow
        onClick={() => setDisplayScreen("")}
        style={{ cursor: "pointer" }}
      />
      <StyledPrimaryTypography style={{ marginTop: "25px" }}>
        Login{" "}
      </StyledPrimaryTypography>

      <TextField
        name="email"
        value={data.email}
        onChange={handleData}
        placeholder="Email"
        InputProps={{ disableUnderline: true }}
        sx={{ mt: 3, mb: 2 }}
        inputProps={{ maxLength: 125 }}
      />
      <ErrorTypography>{errorText.email}</ErrorTypography>
      <TextField
        type={passwordType}
        name="password"
        value={data.password}
        onChange={handleData}
        placeholder="Password"
        InputProps={{
          disableUnderline: true,
          endAdornment:
            passwordType === "password" ? (
              <PasswordVisible
                onClick={() => setPasswordType("text")}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <PasswordInvisible
                onClick={() => setPasswordType("password")}
                style={{ cursor: "pointer" }}
              />
            ),
        }}
        sx={{ mt: 3, mb: 2 }}
      />
      <ErrorTypography>{errorText.password}</ErrorTypography>
      <ErrorTypography>{responseError}</ErrorTypography>
      <Button
        disabled={loadingBtn}
        onClick={handleLogin}
        fullWidth
        variant="contained"
        sx={{ my: 2 }}
      >
        {" "}
        {loadingBtn ? <CircularProgress size={25} /> : "Login"}
      </Button>

      <StyledTypography
        mt={1}
        textAlign="end"
        sx={{ cursor: "pointer" }}
        color="#4D5761 !important"
        onClick={() => {
          setDisplayScreen("recoverPassword");
        }}
      >
        Forgot password ?
      </StyledTypography>
    </>
  );
};

export default Login;
